import React from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import { graphql } from "gatsby";
import {
  sizes,
  colorAliases,
  fontSizes,
  borderRadius,
  MOBILEBREAKPOINT,
  boxShadow,
} from "../style/design-system";
import useSiteMetadata from "../components/SiteMetadata";
import Layout from "../components/Layout";
import OuterContainer from "../components/OuterContainer";

const FullBleedGrid = styled(OuterContainer)`
  display: grid;
  grid-template-columns:
    auto minmax(200px, calc(${sizes.siteWidth} / 2))
    minmax(200px, calc(${sizes.siteWidth} / 2)) auto;
  margin-top: ${sizes["2xl"]};

  @media (max-width: ${MOBILEBREAKPOINT}) {
    margin-top: ${sizes.lg};
    display: block;
  }
`;

const ImagesOverlapping = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 10% 80% 10%;
  grid-template-rows: 32px 1fr auto;
  grid-column: 2 / 3;
  padding: 0 ${sizes.md} 0 ${sizes.xl};

  @media (max-width: ${MOBILEBREAKPOINT}) {
    padding: 0 ${sizes.lg};
  }
`;

const ImageBehind = styled.img`
  width: 100%;
  display: block;
  grid-column: 1 / 3;
  grid-row: 1 / 3;
`;
const ImageFront = styled.img`
  width: 100%;
  display: block;
  grid-column: 1 / -1;
  grid-row: 1 / -1;
  box-shadow: ${boxShadow.lg};
`;

const FirstTextBox = styled.div`
  grid-column: 3 / 4;
  padding: 0 ${sizes.xl} 0 ${sizes.md};

  @media (max-width: ${MOBILEBREAKPOINT}) {
    padding: 0 ${sizes.lg};
  }
`;

const FirstTextBoxHeader = styled.h3`
  font-weight: 700;
  font-size: ${fontSizes["4xl"]};
  margin: ${sizes.xl} 0 ${sizes.md} 0;

  @media (max-width: ${MOBILEBREAKPOINT}) {
    font-size: ${fontSizes["3xl"]};
  }
`;

const FirstTextBoxText = styled.p``;

const CtaButton = styled.button`
  display: inline-block;
  background: ${colorAliases.red};
  border-radius: ${borderRadius.md};
  border: none;
  color: white;
  padding: ${sizes.xs} ${sizes.md};
  font-size: ${fontSizes.md};
  cursor: pointer;
  margin: 0;
`;

const ContactButton = styled(CtaButton)`
  color: ${colorAliases.red};
  border: 1px solid ${colorAliases.red};
  background: white;
  cursor: pointer;
  margin: 0 ${sizes.md} 0 0;
`;

const ButtonContainer = styled.div``;

const SectionInnerContainer = styled.div`
  width: 100%;
  max-width: ${sizes.siteWidth};
  margin: 0 auto;
  padding: ${sizes["2xl"]} ${sizes.lg} ${sizes.lg} ${sizes.lg};
`;

const NarrowSectionInnerContainer = styled(SectionInnerContainer)`
  width: 80%;
  max-width: 900px;

  @media (max-width: ${MOBILEBREAKPOINT}) {
    width: 100%;
  }
`;

const SectionHeader = styled.h3`
  font-weight: 700;
  font-size: ${fontSizes["3xl"]};
  margin-bottom: ${sizes.md};

  @media (max-width: ${MOBILEBREAKPOINT}) {
    font-size: ${fontSizes["2xl"]};
  }
`;

const SectionText = styled.p``;

export const AboutPageTemplate = ({
  location,
  title1,
  text1,
  title2,
  text2,
  imageBehind,
  imageFront,
  metaTitle,
}) => {
  const { title, siteUrl } = useSiteMetadata();

  return (
    <div>
      <Helmet>
        <title>{`${metaTitle} | ${title}`}</title>
        <link rel="canonical" href={siteUrl + location.pathname} />
      </Helmet>
      <FullBleedGrid>
        <ImagesOverlapping>
          <ImageFront src={`/img/${imageFront}`}></ImageFront>
        </ImagesOverlapping>

        <FirstTextBox>
          <div>
            <FirstTextBoxHeader>{title1}</FirstTextBoxHeader>
            <FirstTextBoxText>{text1}</FirstTextBoxText>
            <ButtonContainer>
              <ContactButton>Kontakt oss</ContactButton>
              {/* <CtaButton>Kom igang</CtaButton> */}
            </ButtonContainer>
          </div>
        </FirstTextBox>
      </FullBleedGrid>

      <OuterContainer>
        <NarrowSectionInnerContainer>
          <SectionHeader>{title2}</SectionHeader>
          <SectionText>{text2}</SectionText>
        </NarrowSectionInnerContainer>
      </OuterContainer>
    </div>
  );
};

const AboutPage = ({ data, location }) => {
  const { markdownRemark: post } = data;
  const {
    title_1,
    text_1,
    title_2,
    text_2,
    image_behind,
    image_front,
    language,
    metaTitle,
  } = post.frontmatter;

  return (
    <Layout language={language}>
      <AboutPageTemplate
        location={location}
        metaTitle={metaTitle}
        title1={title_1}
        text1={text_1}
        title2={title_2}
        text2={text_2}
        imageBehind={image_behind.relativePath}
        imageFront={image_front.relativePath}
      />
    </Layout>
  );
};

export default AboutPage;

export const aboutPageQuery = graphql`
  query AboutPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        metaTitle
        language
        title_1
        text_1
        title_2
        text_2
        image_behind {
          relativePath
        }
        image_front {
          relativePath
        }
      }
    }
  }
`;
